<template>
<div>
    <div class="wrap">
        <form name="mfrm" action="/menu_management_status/index" method="post">
            <div class="con_top mb-10">
                <h2><span>■</span> 상담예약정보</h2>
            </div>
            <div class="con">
                <div class="con_table">
                    <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
                        <tbody>
                            <tr>
                                <th class="w-120px">상담일</th>
                                <td class="left pl-20">
                                    {{consDate}}
                                </td>
                                <th class="w-120px">상담시간</th>
                                <td class="left pl-20 w-400px">
                                    {{consTime}}
                                </td>
                            </tr>
                            <tr>
                                <th class="w-120px">예약현황</th>
                                <td class="left pl-20" colspan="3">
                                    {{reservationStatus}}
                                </td>
                            </tr>
                            <!-- <tr>
                                <th class="w-120px">상담메모</th>
                                <td class="left pl-20" colspan="3">
                                    {{reservationMemo}}
                                </td>
                            </tr> -->
                        </tbody>
                    </table>

                    <div class="con_top mb-10">
                        <h2><span>■</span>고객 기본정보</h2>
                    </div>

                    <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
                        <tbody>
                            <tr>
                                <th class="w-120px">고객번호</th>
                                <td class="left pl-20  w-400px">
                                    {{clientCode}}
                                </td>
                                <th class="w-120px">고객유형</th>
                                <td class="left pl-20">
                                    {{eapType}}
                                </td>
                            </tr>
                            <tr>
                                <th>이름</th>
                                <td class="left pl-20">
                                    {{name}}
                                </td>
                                <th>성별</th>
                                <td class="left pl-20">
                                    {{gender}}
                                </td>
                            </tr>
                            <tr>
                                <th>생년월일</th>
                                <td class="left pl-20">
                                    {{birth}}
                                </td>
                                <th>이메일</th>
                                <td class="left pl-20">
                                    {{email}}
                                </td>
                            </tr>
                            <tr>
                                <th>소속</th>
                                <td class="left pl-20">
                                    {{companyName}}
                                </td>
                                <th>유입경로</th>
                                <td class="left pl-20">
                                    {{routeName}}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <!-- <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00" style="border-top: none;">
                        <tbody>
                            <tr>
                                <th class="w-120px">특이사항</th>
                                <td class="left pl-20 h-40px">
                                    {{clientMemo}}
                                </td>
                            </tr>
                        </tbody>
                    </table> -->

                    <div class="con_top mb-10">
                        <h2><span>■</span>상담정보</h2>
                    </div>

                    <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
                        <tbody>
                            <tr>
                                <th class="w-120px">상담센터</th>
                                <td class="left pl-20  w-400px">
                                    {{centerName}}
                                </td>
                                <th class="w-120px">상담사</th>
                                <td class="left pl-20 reset-br">
                                    {{staffName}}
                                </td>
                                <th class="reset"></th>
                                <td class="left pl-20">

                                </td>
                            </tr>
                            <tr>
                                <th>상담구분</th>
                                <td class="left pl-20">
                                    {{gubun}} {{productName}} ({{productPrice}} 원)
                                </td>
                                <th>상담유형</th>
                                <td class="left pl-20" colspan="3">
                                    {{typeName}}
                                </td>
                            </tr>
                            <tr>
                                <th>상담주제</th>
                                <td class="left pl-20">
                                    {{subjectName}}
                                </td>
                                <th>진행현황</th>
                                <td class="left pl-20" colspan="3">
                                    {{clientStatus}}
                                </td>
                            </tr>
                            <tr>
                                <th>사용가능회기</th>
                                <td class="left pl-20" colspan="5">
                                    {{consCountUse}}회 (총{{consCount}}회)
                                </td>
                            </tr>
                            <!-- <tr>
                                <th>전체상담의목표</th>
                                <td class="left pl-20 reset-br">
                                    내용
                                </td>
                                <th class="reset"></th>
                                <td class="left pl-20 reset-br">

                                </td>
                                <th class="reset"></th>
                                <td class="left pl-20">

                                </td>
                            </tr>
                            <tr>
                                <th>상담계획</th>
                                <td class="left pl-20 reset-br">
                                    내용
                                </td>
                                <th class="reset"></th>
                                <td class="left pl-20 reset-br">

                                </td>
                                <th class="reset"></th>
                                <td class="left pl-20">

                                </td>
                            </tr>
                            <tr>
                                <th>주호소문제</th>
                                <td class="left pl-20 reset-br">
                                    내용
                                </td>
                                <th class="reset"></th>
                                <td class="left pl-20 reset-br">

                                </td>
                                <th class="reset"></th>
                                <td class="left pl-20">

                                </td>
                            </tr>
                            <tr>
                                <th>상담과정</th>
                                <td class="left pl-20 reset-br">
                                    내용
                                </td>
                                <th class="reset"></th>
                                <td class="left pl-20 reset-br">

                                </td>
                                <th class="reset"></th>
                                <td class="left pl-20">

                                </td>
                            </tr>
                            <tr>
                                <th>위험단계</th>
                                <td class="left pl-20 reset-br">
                                    4단계 고 위험군:<br>
                                    자살시도 및 자해, 상해 등 위급사항의 발생 또는 발생 가능성이 높은 단계
                                </td>
                                <th class="reset"></th>
                                <td class="left pl-20 reset-br">

                                </td>
                                <th class="reset"></th>
                                <td class="left pl-20">

                                </td>
                            </tr> -->
                        </tbody>
                    </table>

                    <div class="btns3 mgB80">
                        <a class="btn_sms mr-10 pointer" @click="cancel()">취소</a>
                        <a class="btn_cancle mr-10 pointer" @click="remove()">삭제</a>
                        <a class="btn_cancle pointer" @click="modify()">수정</a>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import {
    GetUrlParams
} from '@/utils/common.js'
export default {
    data: () => ({
        idx: -1,
        idxClient: -1,
        clientMemo: '',
        name: '',
        clientCode: '',
        eapType: '',
        gender: '',
        birth: '',
        email: '',
        companyName: '',
        routeName: '',

        idxReservation: -1,
        reservationMemo: '',
        reservationStatus: '',
        centerName: '',
        staffName: '',
        typeName: '',
        gubun: '',
        productName: '',
        productPrice: '',
        subjectName: '',
        consCount: 0,
        consCountUse: 0,
        consCountRest: 0,
        clientStatus: '',
        consTime: '',
        consDate: '',

    }),

    mounted() {
        var oParams = GetUrlParams()
        this.idx = oParams.idx
        this.getReservation(this.idx)
    },

    methods: {

        getReservation(idx) {
            var params = {
                idx: idx
            }
            this.axios.get(`/api/v1/cons/${idx}`, {})
                .then(res => {
                    if (res.data.err === 0) {

                        this.idxReservation = res.data.resVO.idx
                        this.reservationMemo = res.data.resVO.memo
                        this.staffName = res.data.resVO.staffName
                        this.centerName = res.data.resVO.centerName
                        this.consDate = res.data.resVO.consDate
                        if(res.data.resVO.consTime.length >= 84) {
                            res.data.resVO.consTime = res.data.resVO.consTime.substring(0,84) + '\n' + res.data.resVO.consTime.substring(84)
                        }
                        this.consTime = res.data.resVO.consTime
                        this.reservationStatus = res.data.resVO.reservationStatus
                        this.gubun = res.data.clientVO.gubun
                        this.productName = res.data.clientVO.productName
                        this.productPrice = res.data.clientVO.productPrice
                        this.typeName = res.data.clientVO.typeName
                        this.clientStatus = res.data.clientVO.clientStatus
                        this.consCount = res.data.clientVO.consCount
                        this.consCountUse = res.data.clientVO.consCountUse
                        this.consCountRest = res.data.clientVO.consCountRest
                        this.name = res.data.clientVO.name
                        this.gender = res.data.clientVO.gender
                        this.birth = res.data.clientVO.birth
                        this.email = res.data.clientVO.email
                        this.routeName = res.data.clientVO.routeName
                        this.companyName = res.data.clientVO.companyName
                        this.clientMemo = res.data.clientVO.memo
                        this.clientCode = res.data.clientVO.clientCode
                        this.eapType = res.data.clientVO.eapType

                        if(res.data.clientVO.gubun === '기타') {
                            this.productPrice = res.data.clientVO.etcPrice
                        }

                    }
                })
        },

        // 상담예약관리 페이지로 돌아가기
        cancel() {
            //this.$router.push('/convention/consult_reserve')
            history.back()
        },

        // 상담예약 삭제
        // 예약 삭제
        remove() {
            var con_test = confirm("삭제하면 복구가 불가능합니다.\n정말 삭제하시겠습니까?");
            
            if (con_test == true) {
                this.axios.delete(`/api/v1/cons/${this.idx}`,
                    )
                    .then(res => {
                        if (res.data.err === 0) {
                            alert('삭제되었습니다.')
                            this.$router.push('/convention/consult_reserve')
                        } else {
                            alert(res.data.result)
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        alert(err)
                    })
            } else {
                return false
            }

        },

        // 상담예약 수정페이지로 이동
        modify() {
            this.$router.push(`/convention/consult_reserve_modify?idx=${this.idx}`)
        }
    }
}
</script>
